import React from "react";
import { DotProps } from "react-multi-carousel";

const CustomDot: React.FunctionComponent<DotProps> = ({
  onClick,
  active,
}): React.ReactElement => {
  return (
    <span
      onClick={(e) => {
        if (onClick) {
          onClick();
        }
        e.preventDefault();
      }}
      className={active ? "custom-dot custom-dot--active" : "custom-dot"}
    >
      &bull;
    </span>
  );
};

export default CustomDot;

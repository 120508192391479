import React from "react";
import { ButtonGroupProps } from "react-multi-carousel";
import Chevron from "../../components/Arrows/Chevron";

const ButtonGroup: React.FunctionComponent<ButtonGroupProps> = (
  props: ButtonGroupProps
): React.ReactElement => {
  const { next, previous, carouselState } = props;
  const currentSlide = carouselState?.currentSlide ?? 0;
  const slidesToShow = carouselState?.slidesToShow ?? 0;
  const totalItems = carouselState?.totalItems ?? 0;
  const shouldShowNextButton = totalItems > currentSlide + slidesToShow;
  const shouldShowPrevButton = currentSlide !== 0;

  return (
    <div className="slider__buttonsGroup">
      {shouldShowPrevButton && (
        <button
          aria-label="Previous package"
          className="slider__buttonsGroup--left"
          onClick={() => previous && previous()}
        >
          <Chevron />
        </button>
      )}
      {shouldShowNextButton && (
        <button
          aria-label="Next package"
          className="slider__buttonsGroup--right"
          onClick={() => next && next()}
        >
          <Chevron />
        </button>
      )}
    </div>
  );
};

export default ButtonGroup;

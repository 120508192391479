import React from "react";

type ChevronProps = {
  style?: React.CSSProperties;
};

const Chevron: React.FunctionComponent<ChevronProps> = ({ style }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      style={style}
      xmlSpace="preserve"
    >
      <g>
        <path d="M339.9,10.7L120.4,230.1c-6.8,6.8-10.7,16.2-10.7,25.9c0,9.6,3.9,19,10.7,25.9l219.4,219.4c14.3,14.3,37.4,14.3,51.7,0   c14.3-14.3,14.3-37.4,0-51.7L198,256L391.6,62.4c14.3-14.3,14.3-37.4,0-51.7C377.3-3.6,354.1-3.6,339.9,10.7L339.9,10.7z" />
      </g>
    </svg>
  );
};

export default Chevron;

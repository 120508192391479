import React from "react";
import Carousel from "react-multi-carousel";
import CustomDot from "./CustomDot";
import ButtonGroup from "./ButtonGroup";
import { IDynamicBlockComponent } from "../../dynamicPageBuilder/IDynamicBlockComponent";

type SlideItem = {
  css: string;
  html_content: string;
};

type SliderProps = {
  data: Array<{
    element: Record<string, SlideItem>;
    title: string;
    subheader: string;
    background: string;
    layout: string;
  }>;
};

const Slider: React.FunctionComponent<IDynamicBlockComponent> = ({
  blockData,
}): React.ReactElement => {
  const { data: dataArray } = blockData as SliderProps;
  const [data] = dataArray;

  const responsive = {
    all: {
      breakpoint: { max: 8000, min: 0 },
      items: 1,
    },
  };

  const { title, subheader, background, layout, element } = data;
  const slides = Object.values(element);

  const getLayoutClass = (): string => {
    switch (layout) {
      case "left":
        return "sliderContainer__layout--left";

      case "right":
        return "sliderContainer__layout--right";

      default:
        return "sliderContainer__layout--right";
    }
  };

  const getBackgroundStyle = (): React.CSSProperties => {
    switch (background) {
      case "yellow":
        return {
          backgroundImage: `Url(/blocks/Slider/sliderBackground.png)`,
          backgroundColor: "#f7b52f",
        };

      case "violet":
        return {
          backgroundImage: `Url(/blocks/Slider/sliderBackgroundViolet.jpg)`,
          backgroundColor: "#8f89e0",
          backgroundSize: "contain",
        };

      default:
        return {
          backgroundImage: "none",
          backgroundColor: "initial",
        };
    }
  };

  return (
    <div className="slider" style={getBackgroundStyle()}>
      <div className={`desktop-container sliderContainer ${getLayoutClass()}`}>
        <div className="sliderContainer__title">
          <h2>{title}</h2>
          {subheader && (
            <div className="sliderContainer__subtitle">{subheader}</div>
          )}
        </div>
        <div className="sliderContainer__slides">
          <Carousel
            swipeable
            draggable
            arrows={false}
            showDots
            renderDotsOutside
            responsive={responsive}
            renderButtonGroupOutside
            customDot={<CustomDot />}
            customButtonGroup={<ButtonGroup />}
          >
            {slides.map(({ css, html_content }, index) => {
              const html = css
                ? `<style>${css}</style> ${html_content}`
                : html_content;
              return (
                <div
                  className="slider__singleSlide"
                  key={`content-${index}`}
                  dangerouslySetInnerHTML={{ __html: html }}
                />
              );
            })}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Slider;
